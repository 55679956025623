@import "~bootstrap/scss/bootstrap-grid.scss";

.coupon {
    margin: 1em 0;
}

.hidden {
    display: none;
}

.coupon-modal.modal-dialog {

    @include media-breakpoint-down(md) {
        width: 90%;
    }

    .lottery-logo {
        margin: 1em;
        width: 25%;
    }


    h2 {
        @include media-breakpoint-down(md) {
            font-size: 1.4em;
        }
    }
}


@media print {
    .print-coupon-btn {
        display: none;
    }
}
