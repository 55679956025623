@import "~bootstrap/scss/bootstrap-grid.scss";

.allocatable-form-modal {
    .modal-body{
        padding:0!important
    }
    .modal-header {
        display: none;
    }

    .allocatable-entries-form {
        width: 100%;
    }
}