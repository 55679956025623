#my-notifications {

    label {
        font-size: 0.875rem;
        margin-left: 1.3215rem;
        font-weight: 500 !important;
    }

    .notification-type {
        margin: 1rem 0;

        h3 {
            font-size: 1rem;
            font-weight: bold;
        }

        .nested-notification-filters {
            margin-left: 4.5rem;
        }
    }
}
