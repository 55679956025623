// colors - taken from DE Style guide - kept the same names.
$white: #fff;
$black: #000;
$grey1: #ebebeb;
$grey2: #808080;
$grey3: #f4f4f4;
$theme-blue: #065596;
$lottery-blue: #007cc2;
$blue-shade: #00639c;
$blue-tint: #43c5f3;
$lottery-red: #ef3e42;
$red-shade: #bf3134;
$red-tint: #f58b8d;
$lottery-yellow: #ffdf1b;
$yellow-shade: #ccb216;
$yellow-tint: #ffeb76;
$mint: #51c69A;
$violet: #ad03ff;
$orange: #f96028;


$theme-default: $black;
$theme-default-light: $grey2;
$theme-default-lightest: $grey2;
$theme-default-dark: $grey1;
$theme-primary: $lottery-blue;
$theme-primary-dark: $blue-shade;
$theme-primary-light: $blue-tint;
$theme-primary-lightest: $blue-tint;
$theme-secondary: $lottery-yellow;
$theme-secondary-dark: $yellow-shade;
$theme-success: $mint;
$theme-success-dark: $mint;
$theme-danger: $lottery-red;
$theme-danger-dark: $red-shade;

$theme-primary-active: $blue-shade;
$theme-secondary-active: $yellow-shade;

// Gauge Colors
$gauge-0-color: #EC2127;
$gauge-1-color: #FFCC00;
$gauge-2-color: #6484C3;
$gauge-3-color: #026A02;
$gauge-4-color: #01326F;
$gauge-5-color: #D02032;

// utilities
$border-radius-sm: 2px;
$border-radius-md: 5px;
$border-radius-lg: 10px;


// elements
$hero-bg: $lottery-yellow;
$primary-nav-bg-hover: $theme-primary-lightest;
$primary-nav-bg-active: $white;
$primary-nav-text: $white;
$primary-nav-text-active: $theme-primary-active;
$content-box-border: $theme-default;
$logged-in-dashboard: $theme-danger-dark;

$mobile-nav-bg: $lottery-red;
$mobile-nav-bg-active: $lottery-red;
$mobile-nav-text: $white;
$mobile-nav-text-active: $white;

$content-box-text: $white;
$content-box-background: $lottery-blue;
$content-box-outline: $grey1;
$content-box-radius: $border-radius-md;

