@import "~bootstrap/scss/bootstrap-grid.scss";

.form-step {
    &.step4 {
        h3,
        strong,
        span,
        a {
            color: #767676;
        }

        span {
            display: inline-block;
        }

        table {
            min-width: 60%;

            td {
                vertical-align: top;
                text-align: left;
            }
        }

        .edit-info {
            text-decoration: underline;
        }
    }
}

.registration-progress-bar {
    padding: 0.3em 0;
    display: flex;
    flex: 0 0 100%;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    margin: 0 auto;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 70%;
        top: 34%;
        height: 0;
        border-top: 3px solid #969696;
    }

    @include media-breakpoint-up(xs) {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        width: 70%;
    }

    @include media-breakpoint-up(lg) {
        width: 50%;
    }
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100px;
    justify-content: center;

    .circle {
        background-color: #fff;
        border-radius: 50%;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .35);
        cursor: default;
        font-size: 1.6em;
        font-weight: bolder;
        height: 60px;
        padding-top: calc(30px - 0.8em);
        text-align: center;
        width: 60px;
        z-index: 100;

        @include media-breakpoint-up(sm) {
            font-size: 2em;
        }
    }

    &.active {
        .circle {
            color: #fff;
            background: #969696;
        }
    }

    .step-title {
        font-size: 0.7em;
        display: block;
        padding-top: 10px;

        @include media-breakpoint-up(sm) {
            font-size: 1em;
        }
    }
}

.password-field {
    position: relative;
}
