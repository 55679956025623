// This site uses typekit fonts loaded via public/index.html
// <link rel="stylesheet" href="https://use.typekit.net/zhd1xsa.css" />

@font-face {
    font-family: 'icomoon';
    src: url('../assets/fonts/icomoon/icomoon.eot?');
    src: url('../assets/fonts/icomoon/icomoon.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../assets/fonts/icomoon/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/OpenSans/OpenSans.woff2') format('woff2'), url('../assets/fonts/OpenSans/OpenSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/OpenSans/OpenSans-Semibold.woff2') format('woff2'), url('../assets/fonts/OpenSans/OpenSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'), url('../assets/fonts/OpenSans/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham/Gotham-Book.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham/Gotham-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Gotham-Black';
    src: url('../assets/fonts/Gotham/Gotham-Black.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Gotham-Black';
    src: url('../assets/fonts/Gotham/Gotham-Black.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Gotham-Med';
    src: url('../assets/fonts/Gotham/Gotham-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Gotham-Book';
    src: url('../assets/fonts/Gotham/Gotham-Book.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Gotham-Med';
    src: url('../assets/fonts/Gotham/Gotham-Medium.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}


@font-face {
    font-family: "Proxima N W01SC Reg";
    src: url('../assets/fonts/ProximaNW01Reg/ProximaNW01Reg.woff') format('woff');
    src: url('../assets/fonts/ProximaNW01Reg/ProximaNW01Reg.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima N W01 Smbd";
    src: url('../assets/fonts/ProximaNW01Smbd/ProximaNW01Smbd.woff') format('woff');
    src: url('../assets/fonts/ProximaNW01Smbd/ProximaNW01Smbd.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}



%theme-font-standard {
    font-family: "Gotham", sans-serif;
    font-weight: 400;
}

%theme-font-semi-bold {
    font-family: "Proxima N W01 Smbd","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
}

%theme-font-standard-bold {
    font-family: "Gotham", sans-serif;
    font-weight: 900;
}

%theme-font-standard-heavy {
    font-family: "Gotham-Black", sans-serif;
    font-weight: bold;
}

%theme-font-alternate {
    font-family: "Gotham-Med", sans-serif;
    font-weight: 600;
}

%theme-font-alternate-bold {
    font-family: "Gotham-Med", sans-serif;
    font-weight: bold;
}

%theme-font-alternate-book {
    font-family: "Gotham-Book", sans-serif;
    font-weight: 400;
}
