// general styles
body {
    font-family: 'Gotham', Arial, sans-serif;
    @extend %theme-font-standard;
    background-color: $white;
    line-height: 1.42857143rem;

    b,
    h1,
    h2,
    h3,
    h4 {
        @extend %theme-font-standard-bold;
    }

    strong {
        @extend %theme-font-semi-bold;
    }

    label {
        @extend .font-weight-bold;
        font-size: 1.125em;
    }

    .error-box {
        @extend %theme-font-standard-bold;
        @extend .p-3;
        border: 3px solid $theme-danger;
        color: $theme-danger;
    }

    // Needed to fix Safari and Edge password autofill icons.
    .password-show {
        right: 40px !important;
    }
}

.content-box.no-collapse {
    .content-box-head {
        @extend .px-3;
    }
}

nav {
    display: block;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul ul {
    margin-bottom: 0;
}

a {
    color: $black;
    text-decoration: underline;
    background-color: transparent;
}

img {
    vertical-align: middle;
    border-style: none;
}

input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input {
    overflow: visible;
}

.gauge {
    font-size: 0.875rem;
    margin-left: 0 !important;

    .bar-label {
        margin-bottom: 0;
    }
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 576px) {
    .content-root.home .hero-carousel {
        margin-bottom: -5%;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }
    .d-md-block {
        display: block !important;
    }
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

@media (min-width: 768px) {
    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }
}

@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    img {
        page-break-inside: avoid;
    }
    .container {
        min-width: 992px !important;
    }
}

[class^='icon-'] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
    content: '\e902';
}

.icon-instagram:before {
    content: '\e903';
}

.icon-linkedin:before {
    content: '\e905';
}

.icon-search:before {
    content: '\e907';
}

.icon-twitter:before {
    content: '\e908';
}

.icon-youtube:before {
    content: '\e909';
}

@media (max-width: 575px) {
    .social-networks li a span {
        font-size: 45px !important;
    }
}

a {
    outline: 0;
    transition: color 0.25s ease-in-out, background 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

a:hover,
a:focus {
    outline: 0;
    text-decoration: none !important;
}

@media (min-width: 768px) {
    .nav-drop {
        padding: 0;
        position: static;
        background: none;
        transition: none;
        border: 0;
    }

    .header-search-form {
        max-width: 240px;
        width: auto;
        margin: 0;
    }
    .header-search-form .form-group {
        margin: 0 0 0 8px;
        width: 197px;
        padding: 0;
    }
}

.btn-submit {
    color: #fff;
    background-color: $grey2;
}

::placeholder {
    color: #808080 !important;
}

::-moz-placeholder {
    color: #808080 !important;
}

::-webkit-input-placeholder {
    color: #808080 !important;
}

:-ms-input-placeholder {
    color: #808080 !important;
}

:-moz-placeholder {
    color: #808080 !important;
}

// loading overlay
.loading-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    border-radius: 0px;
    left: 0;
    top: 0;
}

// themed divider
.step-divider {
    @extend .my-3;
    background-color: $lottery-yellow;
    height: 2px;
    width: 90px;
}

// site header
.page-header {
    header {
        @extend .pt-3;
        background: $white;

        .header-lottery-logo-container {
            a {
                @extend .d-block;
            }
        }
    }
}

// site footer
.page-footer {
    @extend .py-3;

    background-color: $white;
    color: $lottery-blue;
    font-size: 13px !important;
    line-height: 1em;

    .container {
        padding-left: 0px;
        padding-right: 0px;
        max-width: 1170px;
        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
            display: flex;
            flex-direction: row-reverse;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .margin-top-1em {
        margin-top: 1em;
    }
    .inner-col-padding {
        padding: 2rem 0;
    }

    /* For social Media Icons in last column */
    .col {
        &:last-of-type {
            .nav-item {
                &:nth-last-child(-n + 3),
                &:nth-last-child(-n + 2),
                &:nth-last-child(-n + 1) {
                    margin-top: 2rem;
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }

    .font-weight-600 {
        font-weight: 450;
    }
    .social-wrap {
        display: block;
        margin-top: 2em;
        margin-right: 2px;
    }
    @include media-breakpoint-up(md) {
        .social-wrap:last-of-type {
            margin-right: 0 !important;
        }
        .social-wrap:first-of-type {
            margin-left: 0 !important;
        }
    }
    @include media-breakpoint-down(md) {
        .social-wrap:last-of-type {
            margin-right: auto;
        }
        .social-wrap:first-of-type {
            margin-left: auto;
        }
    }

    .social {
        padding: 0.4em 0.4em;
        color: #fff;
        background-color: #007cc2;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 14px;
    }

    .nav-item {
        width: 100%;
        line-height: 1.15626rem;

        a {
            font-family: 'Proxima N W01SC Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            color: $lottery-blue;
            text-decoration: none;
            padding: 0.125em 0;
            text-align: left;
        }

        a:hover {
            color: #004b76;
        }

        a.mtm {
            margin-top: 2em;
        }

        a.mts {
            margin-top: 1em;
        }

        .icon {
            width: 30px;
            height: 30px;
            display: inline-block;
            font-size: 1.375rem;
        }
        @include media-breakpoint-down(sm) {
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }
    @media (max-width: 767px) {
        .col-sm-6 {
            min-width: 100% !important;
        }

        .col-sm-3 {
            min-width: 100% !important;
        }
    }
}

.footer-disclaimer {
    background: $grey1;
    color: #191919;
    padding: 1rem;
    font-size: 0.75rem;

    .container {
        margin-right: auto;
        margin-left: auto;
        padding-left: 0px !important;
        padding-right: 0px !important;
        max-width: 1170px;
    }
    .row {
        div {
            &:first-child {
                text-align: left;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        a {
            color: $lottery-blue;
            text-decoration: none;
        }
    }

    .copyright {
        font-size: 0.6875rem;
    }
    .app-store-image {
        width: 100%;
        max-width: 150px;
    }

    .app-store-text {
        line-height: 1.3em;
    }
}

// themed text
.theme-text-muted {
    color: $theme-default;
}

.theme-text-default {
    color: $theme-default;
}

.theme-text-default-dark {
    color: $theme-default-dark;
}

.theme-text-primary {
    color: $theme-primary;
}

.theme-text-secondary {
    color: $theme-secondary;
}

.theme-text-success {
    color: $theme-success;
}

.theme-text-danger {
    color: $theme-danger;
}

// points header
.points-dashboard {
    @extend %theme-font-standard-bold;
    @extend .row;

    label {
        font-size: 0.9em;
    }

    .expiring {
        color: $theme-danger-dark;
    }

    .logged-out-btn {
        @extend .w-100;
        @extend .px-2;
    }

    .dashboard-tooltip {
        color: $lottery-blue;
        margin-left: 5px;
    }

    .my-user-icon {
        color: $lottery-blue;
        font-size: 2rem;
    }

    .content-box {
        .content-box-head {
            background-image: linear-gradient(180deg, #f76271, #d45664);
            padding: 0.875rem 0;
        }
    }

    .points-dashboard-item {
        @extend .text-center;
        margin: 0 auto;

        .btn {
            background-color: $white;
            font-size: 1em;
            width: 90%;
            box-shadow: none;
            border: 1px solid $lottery-blue;
        }
    }

    .my-account {
        .my-account-img {
            @extend .rc-all-full;
            color: $lottery-blue;
        }

        .account-nav {
            @extend .rc-all-md;
            border: 1px solid $theme-default-lightest;
            box-shadow: 0 2px 2px $theme-default;
            font-size: 0.9em;
            transform: translateX(-100%);

            .nav-item {
                background-color: $white;
                border-bottom: 1px solid $theme-default-lightest;
                line-height: 1em;

                &:hover {
                    background-color: $lottery-yellow;
                }

                &:first-child {
                    @extend .rc-top-md;
                }

                &:last-child {
                    @extend .rc-bottom-md;
                    border: 0 none;
                }

                .nav-link {
                    color: $theme-primary;
                    padding: 1em;
                }
            }
        }
    }
}

// themed alerts
.alert {
    @extend %theme-font-standard-bold;
    @extend .d-flex;
    @extend .align-items-center;
    font-size: 0.9em;
    line-height: 1.2em;

    ul {
        @extend .list-unstyled;
    }

    .alert-text {
        @extend .col;
        @extend .m-0;
    }

    &.alert-danger {
        background-color: transparent;
        border-color: $theme-danger;
        box-shadow: inset 0 3px 5px -4px $theme-default;
        color: $theme-danger;
    }

    &.alert-success {
        background-color: transparent;
        border-color: $theme-success;
        box-shadow: inset 0 3px 5px -4px $theme-default;
        color: $theme-success;

        &.heading-alert {
            border-radius: 0 !important;
            background: $mint;
            color: #fff;

            a {
                color: $white;
                text-decoration: underline;
                cursor: pointer;
            }

            .alert-heading {
                display: block;
                width: 100%;
            }
        }
    }

    svg {
        font-size: 1.2em;
    }
}

.alert.web-notice {
    display: block !important;
    border-radius: 0.5em !important;
    margin: 0.5rem;

    .web-notice-heading {
        display: flex !important;
        align-items: center !important;

        h4 {
            font-size: 1.2rem;
            line-height: 1em;
            vertical-align: text-bottom;
            margin-bottom: 0;
        }

        svg,
        i {
            font-size: 1.2rem;
            vertical-align: text-bottom;
            margin-right: 0.5em;
        }
    }

    .web-notice-body {
        > p:last-child {
            margin-bottom: 0;
        }
    }

    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    // variants
    &.notice-primary {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
    }

    &.notice-secondary {
        color: #383d41;
        background-color: #e2e3e5;
        border-color: #d6d8db;
    }

    &.notice-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    &.notice-danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    &.notice-warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
    }

    &.notice-info {
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #bee5eb;
    }

    &.notice-light {
        color: #818182;
        background-color: #fefefe;
        border-color: #fdfdfe;
    }

    &.notice-dark {
        color: #1b1e21;
        background-color: #d6d8d9;
        border-color: #c6c8ca;
    }
}

// themed content boxes
.content-box {
    @extend .rc-all-md;
    @extend .mb-4;
    @extend .shadow-sm;
    border-radius: $border-radius-md;

    background-color: $white;
    box-shadow: 0px 0px 12px #00000080 !important;

    &.danger {
        @extend .theme-text-danger;
    }

    .content-box-head {
        @extend .rc-top-md;
        @extend %theme-font-standard-heavy;
        background-color: $content-box-background !important;
        color: $content-box-text;
        font-size: 1.3125rem;
        text-align: center;
        text-transform: uppercase;
        padding-top: 0.875rem !important; // Override sg-ui-components
        padding-bottom: 0.875rem !important; // Override sg-ui-components
    }

    .collapser .toggle-control {
        color: $content-box-text !important;
    }

    &.content-is-hidden {
        .content-box-head {
            @extend .rc-bottom-md;
        }
    }

    &.mint .content-box-head {
        background-color: $mint;
        color: $white;
    }

    &.theme-blue .content-box-head {
        background-color: $lottery-blue;
        color: $white;
    }

    &.theme-grey .content-box-head {
        background-color: $grey1;
        color: $white;
    }

    &.theme-pink .content-box-head {
        background-color: $red-tint;
        color: $white;
    }

    &.theme-darkest-gray .content-box-head {
        background-color: $grey2;
        color: $white;
    }

    &.theme-red .content-box-head {
        background-color: $theme-danger;
        color: $white;
    }

    &.theme-primary .content-box-head {
        background-color: $content-box-background;
        color: $content-box-text;
    }

    &.theme-red-tint .content-box-head {
        background: transparent linear-gradient(180deg, #f76271 0%, #d45664 100%) 0% 0% no-repeat padding-box;
        color: $content-box-text;
    }
}

//? Home page content boxes should have same size on desktop
#home-enter-ticket-container,
#enter-enter-ticket-container,
#home-next-drawing-container {
    .content-box > .content-box-body {
        @media screen and (min-width: 992px) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            min-height: 16rem;
        }
    }
}

// themed buttons
.rounded-pill {
    border-radius: $border-radius-md;
}

.theme-btn {
    @extend %theme-font-alternate;
    font-size: 1.2em;
    margin: 0 0.25em;
    padding: 0.25em 2rem;
    text-decoration: none;

    .disabled {
        background-color: $theme-default;
        opacity: 1;
    }

    &.inset {
        box-shadow: inset 0 2px 2px $theme-default;
    }

    &.theme-outline {
        background-color: $white;
        color: $lottery-blue;
        border: 2px solid $lottery-blue;

        &:active {
            background-color: $grey1;
        }

        &:disabled {
            background-color: $white;
            opacity: 0.5;
        }
    }

    &.theme-primary {
        background: $lottery-blue;
        color: $white;

        &:active {
            background-color: $theme-primary-active;
        }

        &:disabled {
            background-color: $lottery-blue;
            opacity: 0.5;
        }
    }

    &.theme-secondary {
        background: $lottery-yellow;
        color: $black;
        box-shadow: 0 2px 2px #00000040;
        border-radius: 2px;

        &:active {
            background-color: $theme-secondary-active;
        }

        &:disabled {
            background: $lottery-yellow;
            opacity: 0.5;
        }
    }

    &.theme-success {
        background-color: $theme-success;
        color: $white;

        &:active {
            background-color: $theme-success-dark;
        }
    }

    &.theme-danger {
        background-color: $theme-danger;
        color: $white;

        &:active {
            background-color: $theme-danger-dark;
        }
    }
}

.link-back-btn {
    @extend .theme-btn;
    @extend .theme-secondary;
}

// themed form elements
.theme-form {
    color: $black;

    label:not(.form-check-label) {
        @extend %theme-font-alternate;
        @extend .mb-1;
        font-size: 1.125rem;
    }

    .form-step {
        @extend .row;
        @extend .justify-content-center;

        .inner-step {
            @extend .col-md-10;
            @extend .col-lg-8;
        }
    }
}

.theme-input {
    border: 1px solid $lottery-blue;
    padding: 0.375rem 0.75rem;
}

.theme-select {
    @extend .theme-input;
}

// themed tables
.theme-table-group-title {
    @extend %theme-font-alternate;
    @extend .text-uppercase;
    font-size: 1.2em;
    border: 0 none;
    border-radius: $border-radius-md;
    color: $white;
    text-align: center;
    padding: 0.75rem;
    margin: 5px 0;
}

.theme-table {
    thead {
        th {
            @extend %theme-font-alternate-book;
            @extend .text-uppercase;
            background-color: $white;
            border: 0 none;
            color: $black;
            border-bottom: 4px solid #43c5f3;
            font-size: 1.0625rem;

            &:first-child {
                @extend .rc-tl-md;
                @extend .rc-bl-md;
            }

            &:last-child {
                @extend .rc-tr-md;
                @extend .rc-br-md;
            }
        }
    }

    tr {
        &:nth-child(even) {
            td {
                background-color: $grey1;
            }
        }

        td {
            border: 0 none;

            &:first-child {
                @extend .rc-tl-md;
                @extend .rc-bl-md;
            }

            &:last-child {
                @extend .rc-tr-md;
                @extend .rc-br-md;
            }
        }
    }

    &.mobile {
        @extend .w-100;
        max-width: 100%;
        overflow: auto;

        tr {
            td {
                @extend .text-left;
                background-color: transparent;
                min-width: 150px;
                white-space: nowrap;

                &:nth-child(odd) {
                    background-color: #eee;
                    border-radius: 0;
                }

                &:first-child {
                    @extend %theme-font-alternate;
                    @extend .text-white;
                    border-radius: 0;
                    left: 0;
                    text-transform: uppercase;
                    max-width: 120px;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    background: $lottery-blue;
                }

                + td {
                    border-left: 2px solid $white;
                }
            }

            + tr {
                border-top: 2px solid $white;
            }
        }
    }
}

// site_default
.lottie {
    margin-left: auto;
    margin-right: auto;
}

.hero {
    background: $hero-bg;
    color: $white;
    font-size: 5em;
    text-align: center;
    padding: 0.75em 0.25em;
    line-height: 0.85em;

    @media screen and (min-width: 768px) {
        min-height: 220px;
    }
}

.navbar {
    padding: 0;
    border-radius: 0;

    .navbar-nav {
        .nav-link {
            font-weight: 700;
            color: $primary-nav-text;
            text-decoration: none;
            padding: 1.2rem 1rem;

            &:hover,
            &.active {
                color: $white;
                text-decoration: underline;
                transition: none;
            }
        }

        .has-dropdown {
            .dropdown.show {
                .nav-link {
                    color: $white;
                    transition: none;
                }
            }

            .dropdown-menu {
                @extend .rc-bottom-md;
                box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.35);
                padding: 0;
                border: none;
                border-radius: 0;

                .dropdown-item {
                    text-decoration: none;
                    font-weight: 600;
                    background: $lottery-red url('../assets/images/diamond-pattern.png') repeat;
                    color: $white;

                    &:focus,
                    &:active,
                    &:hover,
                    &.active {
                        color: $black;
                        background-color: $lottery-yellow;
                        text-decoration: none !important;
                    }
                }
            }
            z-index: 100;
        }
    }
}

.mobile-nav {
    @extend .px-0;
    @extend .d-block;
    @extend .d-md-none;
    background: $grey1;

    .navbar-brand {
        @extend .p-3;
    }

    .navbar-toggler {
        @extend .mx-3;
    }

    .fa-bars {
        display: none;
    }

    .fa-xmark {
        display: block;
    }

    .collapsed {
        .fa-bars {
            display: block;
        }

        .fa-xmark {
            display: none;
        }
    }

    .toolbar-left-menu {
        @extend .text-center;
        @extend .row;
        flex-direction: row;

        .nav-item {
            @extend .col;
            @extend .px-0;

            .nav-link {
                @extend %theme-font-alternate;
                background: $grey3 url('../assets/images/diamond-pattern.png') repeat;
                background-size: 6em 3em;
                color: #333;
                font-size: 0.7rem;
                font-weight: 700;
                padding: 0.2rem 1rem;

                &:hover {
                    background: $lottery-yellow;
                    color: #333;
                }
            }

            &:first-of-type {
                .nav-link {
                    background: $white;
                }
            }
        }
    }

    .toolbar-right-menu {
        @extend .text-center;
        @extend .row;
        @extend .mx-auto;
        flex-direction: row;
        background: $blue-shade;
        font-size: 0.75rem;

        .nav-item {
            @extend .col;
            @extend .px-0;

            .icon {
                @extend .px-1;
            }
        }
    }

    .lottery-menu {
        @extend .row;
        flex-direction: row;
        background: $white;
        @extend .px-2;

        .nav-item {
            @extend .col;
            padding: 0 8px;

            .nav-link {
                @extend %theme-font-alternate;
                @extend .px-0;
                color: $lottery-blue;
                font-weight: 700;
                font-size: 0.65625rem;
                text-align: center;
                line-height: 1.2em;

                .icon {
                    @extend .mx-auto;
                    width: 1.875rem;
                    height: 1.875rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    color: #007cc2;
                    display: block;
                    margin-bottom: 0.125rem;
                }

                .circlecheck {
                    background-image: url('../assets/images/icons/WinningNumbers.svg');
                }

                .navpin {
                    background-image: url('../assets/images/icons/WheretoBuy.svg');
                }

                .delewarestate {
                    background-image: url('../assets/images/icons/CollectNWin.svg');
                }

                .starburst {
                    background-image: url('../assets/images/icons/Playersclub.svg');
                    background-size: contain;
                    width: unset !important;
                }

                .wallet {
                    background-image: url('../assets/images/icons/ClaimPrizes.svg');
                }

                .winnerstar {
                    background-image: url('../assets/images/icons/Winners.svg');
                }
            }
        }
    }

    .primary-menu {
        .nav-link {
            @extend %theme-font-alternate;
            @extend .text-center;
            background: $lottery-red url('../assets/images/diamond-pattern.png') repeat;
            font-weight: 700;
            font-size: 0.875rem;
            text-align: center;
            border-bottom: 2px solid $red-tint;
        }
    }
}

.nav-header-left {
    @extend .col-7;
    @extend .mr-auto;
    @extend .d-md-block;
    @extend .d-none;

    li.nav-item {
        &:first-child {
            margin-left: 0;
        }

        .nav-link {
            @extend %theme-font-alternate;
            background: $grey3 url('../assets/images/diamond-pattern.png') repeat;
            background-size: 6em 3em;
            color: #333;
            font-size: 0.7rem;
            font-weight: 700;
            padding: 0.2rem 1rem;

            &:hover {
                background: $lottery-yellow;
                color: #333;
            }
        }
    }
}

.nav-header-right {
    @extend .col-5;
    @extend .d-md-block;
    @extend .d-none;

    .navbar {
        @extend .ml-auto;

        li.nav-item {
            .nav-link {
                color: $lottery-blue;
                font-size: 0.7rem;
                font-weight: bold;
                padding: 0.2rem 1rem;

                &:hover {
                    color: #333;
                }

                .icon {
                    width: 0.7rem;
                    height: 0.7rem;
                    background-repeat: no-repeat;
                    background-position: bottom;
                    color: #007cc2;
                    display: inline-block;
                    margin-right: 0.2rem;
                }

                .facebook {
                    background-image: url('../assets/images/icons/Facebook.svg');
                }

                .calendar {
                    background-image: url('../assets/images/icons/Calendar.svg');
                }

                .search {
                    background-image: url('../assets/images/icons/Search.svg');
                }
            }
        }
    }
}

.nav-lottery {
    background: $grey1 url('../assets/images/header-top-background.png') repeat-x;
    background-size: contain;
    text-align: center;
    -webkit-box-shadow: inset 0 0.25em 0.125em 0 rgb(0 0 0 / 25%);
    -moz-box-shadow: inset 0 0.25em 0.125em 0 rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0.25em 0.125em 0 rgb(0 0 0 / 25%);
    margin: 0 auto;
    @extend .d-none;
    @extend .d-md-block;

    .navbar-brand {
        @extend .px-0;
        @extend .mx-0;
        @extend .col-xl-4;
        @extend .col-md-4;
        @extend .col-sm-3;

        text-align: left;
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%;
    }

    .navbar-collapse {
        @extend .col-xl-6;
        @extend .col-md-8;
        @extend .col-sm-9;
        @extend .col-lg-offset-2;
        padding-left: 0;
        padding-right: 0;

        li.nav-item {
            display: inline-block;

            .nav-link {
                @extend %theme-font-alternate;
                color: $lottery-blue;
                font-weight: 700;
                font-size: 0.75rem;
                text-align: center;
                padding: 0.5rem 0 0 0;
                margin: 0 1rem 0.5rem 0.1875rem;

                .icon {
                    @extend .mx-auto;
                    width: 1.875rem;
                    height: 1.875rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    color: #007cc2;
                    display: block;
                    margin-bottom: 0.125rem;
                }

                .circlecheck {
                    background-image: url('../assets/images/icons/WinningNumbers.svg');
                }

                .navpin {
                    background-image: url('../assets/images/icons/WheretoBuy.svg');
                }

                .delewarestate {
                    background-image: url('../assets/images/icons/CollectNWin.svg');
                }

                .starburst {
                    background-image: url('../assets/images/icons/Playersclub.svg');
                    background-size: contain;
                    width: unset !important;
                }

                .wallet {
                    background-image: url('../assets/images/icons/ClaimPrizes.svg');
                }

                .winnerstar {
                    background-image: url('../assets/images/icons/Winners.svg');
                }

                &:hover {
                    color: $black;
                }
            }

            .nav-link.active {
                color: $black;
                text-decoration: none;
                border-bottom: 3px solid #be1b1f;
            }

            &:last-child {
                .nav-link {
                    padding-right: 0;
                }
            }
        }
    }
}

.nav-primary {
    background: $lottery-red url('../assets/images/diamond-pattern.png') repeat;
    text-align: center;
    -webkit-box-shadow: inset 0 0.25em 0.125em 0 rgb(0 0 0 / 25%);
    -moz-box-shadow: inset 0 0.25em 0.125em 0 rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0.25em 0.125em 0 rgb(0 0 0 / 25%);
    margin: 0 auto;
    @extend .d-md-block;
    @extend .d-none;

    .navbar-brand {
        img {
            max-width: 210px;

            @include media-breakpoint-up(sm) {
                max-width: 260px;
            }
        }
    }

    .navbar-nav {
        @extend .m-auto;

        li.nav-item {
            .nav-link {
                @extend %theme-font-alternate;
                color: $primary-nav-text;
                font-weight: 700;
                padding: 0.6rem 1.2rem;

                @include media-breakpoint-up(xl) {
                    padding: 1rem 3rem;
                }

                &:hover,
                &.active {
                    background: $lottery-yellow url('../assets/images/diamond-pattern.png') repeat;
                    color: $black;
                    transition: none;
                }
            }
        }
    }
}

// progress bars
.gauge_0 {
    .progress-bar {
        background-color: $gauge-0-color;
    }
}

.gauge_1 {
    .progress-bar {
        background-color: $gauge-1-color;
    }
}

.gauge_2 {
    .progress-bar {
        background-color: $gauge-2-color;
    }
}

.gauge_3 {
    .progress-bar {
        background-color: $gauge-3-color;
    }
}

.gauge_4 {
    .progress-bar {
        background-color: $gauge-4-color;
    }
}

.gauge_5 {
    .progress-bar {
        background-color: $gauge-5-color;
    }
}

.toast-holder {
    position: relative;
}

.toast {
    max-width: 100%;
}

.toast-header {
    justify-content: center;
}

.toast .close {
    position: absolute;
    right: 1em;
    top: 0.7em;
}

.content-root {
    @extend .pt-1;
    background: url('../assets/images/diamond-pattern.png') repeat, url('../assets/images/body-background.jpg') repeat 50% 0;
    background-size: 18em 9em, auto;
    background-color: #d7d7d7;
    padding-bottom: 20px;

    &.home {
        .navbar {
            border-radius: 0;
        }
    }

    .login-container,
    .feedback-container,
    .winners-container,
    .registration-container,
    .my-profile-container,
    .promotions-container,
    .page-not-found-container {
        padding: 0 1em;
    }
}

.mb-dynamic {
    margin-bottom: 3rem;

    @media (max-width: 400px) {
        margin-bottom: 1rem;
    }
}

// breadcrumb
.title-breadcrumb {
    display: none;

    @include media-breakpoint-up(sm) {
        margin-left: 15px;
        display: block;
        position: relative;
    }

    .breadcrumb-arrow {
        @extend .mb-3;
        padding: 0.5rem 0 0 0.5rem;
        background: $white;
        display: inline-block;
        position: relative;

        h1 {
            font-size: 2rem;
        }

        &::after {
            content: '';
            background: url('../assets/images/triangle-no-shadow.svg') no-repeat;
            display: inline-block;
            right: -74px;
            top: 0;
            height: 94px;
            width: 78px;
            position: absolute;
        }
    }

    .section-title {
        color: $lottery-blue;
        padding: 0 1rem;
    }

    .breadcrumb {
        background-color: transparent;
        padding: 0 1rem;
        margin-bottom: 0;
    }

    //for reference content is the > update the content code to change to something else
    .breadcrumb-item + .breadcrumb-item::before {
        content: '\003E';
    }
}


.carousel {
    .carousel-inner {
        display: flex;
        height: 100%;
        margin-bottom: 2em;

        & > * {
            width: 100%;
            padding: 0;

            @media (min-width: 400px) {
                padding: 0.5em 1em;
            }
        }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        display: block;

        &:after {
            content: " ";
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .carousel-control-prev {
        @extend .ml-4;
    }

    .carousel-control-next {
        @extend .mr-4;
    }

    .carousel-control-prev,
    .carousel-control-next {
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 1;
        text-align: center;
        top: inherit;
        background-color: $theme-primary;
        border-radius: 100%;
        bottom: 0.5em;
        height: 30px;
        width: 30px;
    }

    .carousel-indicators {
        @extend .flex-wrap;

        bottom: 0.3em;
        margin: 0 auto;
        max-width: 50%;

        @include media-breakpoint-up(sm) {
            bottom: 1em;
        }

        li {
            @extend .mt-1;

            border-radius: 50%;
            border: 1px solid $theme-default-dark;
            box-shadow: inset 0 3px 6px -1px $theme-default;
            height: 14px;
            width: 14px;

            &.active {
                background-color: $theme-secondary;
                border-color: $theme-secondary;
                box-shadow: 0 2px 3px 0 $theme-default;
            }
        }
    }
}

// refer a friend
.raf-container {
    .raf-input {
        @extend .col;
        @extend .form-control;
        @extend .theme-input;
    }

    .raf-btn {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
        @extend .col-3;
        @extend .col-md-3;
        @extend .text-center;
        @extend .px-0;
    }
}

// winners modal
.winners-modal.modal-dialog .modal-content .modal-header {
    background-color: $theme-primary;
    color: $white;

    a {
        color: $white;
    }

    .modal-close-element {
        font-weight: bold;
    }
}

// enter tickets
.enter-tickets-area {
    .nav-tabs .nav-link {
        @extend %theme-font-alternate;
        background-color: $theme-default-light;
        color: $lottery-blue;
        font-size: 1.3em;
        position: relative;
        text-transform: uppercase;
        text-decoration: none;

        &.active {
            color: $theme-default;

            &:after {
                background-color: $lottery-red;
                bottom: 0;
                content: '';
                display: block;
                height: 3px;
                left: 50%;
                margin-left: -25%;
                position: absolute;
                right: 50%;
                width: 50%;
            }
        }
    }

    p {
        margin-top: 0;
    }

    .tab-content {
        label {
            @extend %theme-font-alternate;
            font-size: 1.2em;
        }

        @include media-breakpoint-down(sm) {
            .theme-input {
                margin-top: 0.25rem;
            }
        }
    }

    .theme-btn {
        padding: 0.25em 3em;
    }

    #enterTicketTabs-tabpane-draw {
        #field1 {
            min-width: 245px;
        }
    }

    #enterTicketTabs-tabpane-scratch {
        #packNum {
            min-width: 85px;
        }

        #validation {
            min-width: 165px;
        }
    }
}

// rewards & promos
.promotion-list {
    @extend .row;

    > div {
        @extend .mt-3;
    }

    > div:first-child {
        margin-top: 0;
    }
}

.promotion-list,
.sweeps-list,
.pfd-list {
    gap: 10px 0px;
    @media (min-width: 768px) {
        padding: 0 5rem;
    }
}

.promotion-card {
    @extend .rc-all-md;
    @extend .mb-3;
    box-shadow: 0px 3px 6px #00000043;
    min-height: 500px;

    p {
        line-height: 1.1em;
        margin: 0;
    }

    p.bar-label {
        line-height: 1.4rem;
    }

    .card-img-top {
        @extend .rc-top-md;
    }

    .promotion-body {
        padding: 1rem;

        .promotion-title {
            @extend %theme-font-standard-bold;
            @extend .mb-2;
            color: $theme-default;
            font-size: 1rem;
        }

        .promotion-description {
            padding-top: 0;
            font-size: 0.875rem;
        }
    }

    .more-info {
        @extend .theme-text-muted;
        @extend .row;
        @extend .align-items-end;
        @extend .mt-1;
        @extend .px-3;
        @extend .pb-3;
        font-size: 0.875rem;

        .more-link {
            @extend .col;
            @extend .pt-0;
            @extend .text-right;

            a {
                color: $black;
            }

            .more-link-icon {
                color: $lottery-blue;
                font-size: 1.2rem;
            }
        }
    }
}

.show-more-section {
    text-transform: capitalize;
    @media (min-width: 400px) {
        margin: 1rem auto;
    }
}

// promotion details
.promotion-details {
    @extend .px-3;
    @extend .mb-3;

    .promotion-image-area {
        @extend .col-12;
        @extend .col-md-4;
        @extend .mb-3;
        @extend .mb-md-0;
        padding: 15px;
        img {
            @extend .rc-all-md;
            width: 100%;
            border-radius: 10%;
        }
    }

    .promotion-details-area {
        @extend .col-12;
        @extend .col-md-8;
        background: $white;
        font-size: 0.875rem;
        box-shadow: 0px 0px 12px #00000080;
    }

    .prizes-tier {
        color: $lottery-blue;
    }

    .title {
        color: $lottery-blue;
        font-size: 2em;
        font-weight: 900;
        @extend .my-3;
    }

    .prize-img-container {
        @extend .pt-4;
        border-top: 4px solid $theme-default-dark;
    }

    .prize-img {
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
    }

    .prizes-tier {
        @extend .mb-0;
        font-size: 1.6em;

        @include media-breakpoint-up(md) {
            font-size: 2em;
        }
    }

    .prizes-title {
        @extend %theme-font-alternate;
        font-size: 1.8em;
    }

    .prizes-description {
        font-size: 1.2em;
        line-height: 1.2em;
    }

    .lottery-promotion-description {
        @media screen and (max-width: 425px) {
            padding: 0rem 1.5rem;
        }
    }
}

// winners
.content-root.winners {
    .content-box:first-child {
        margin-top: 1em;
    }
}

// feedback
.content-root.feedback,
.content-root.terms,
.content-root.contact-us {

    .feedback-container .content-box:first-child {
        margin-top: 1em;
    }

    .feedback-form {
        .form-label {
            font-weight: bold;
        }

        .feedback-submit {
            @extend .btn;
            @extend .theme-btn;
            @extend .theme-primary;
            @extend .btn-lg;
            padding: 0 5rem;
        }
    }

    .about-section {
        @extend .m-3;
        background: $white;
        box-shadow: 0px 0px 12px #00000080;

        .about {
            @extend .p-3;
            vertical-align: middle;
        }

        .players-club-blurb {
            @extend .p-3;
            box-shadow: 0px 0px 12px #00000080;

            h1 {
                color: $lottery-blue;
            }
        }
    }

    .content-box {
        @extend .mx-3;
    }
}

// home
.content-root.home {
    .login-box {
        @extend .rc-bl-md;
        background: transparent linear-gradient(180deg, #0057b8 0%, #b5ceea 50%, #ffffff 100%) 0% 0% no-repeat padding-box;

        .second-chance-logo {
            img {
                @extend .my-4;
                max-width: 140px;
            }
        }

        .login-area {
            text-align: center;
            padding-bottom: 1.5rem;

            .welcome {
                .welcome-text {
                    display: block;
                    font-size: 1.3em;
                }

                .user-name {
                    font-size: 1.6em;
                    text-transform: uppercase;
                }
            }
        }
    }

    .hero-carousel {
        @extend .text-center;
        width: 100%;

        .carousel {
            @extend .mx-auto;

            .carousel-inner {
                @extend .mb-0;

                > * {
                    padding: 0;
                }
            }

            .carousel-item {
                float: none;

                a,
                img {
                    @extend .d-block, .w-100;
                }
            }

            .carousel-indicators {
                @extend .d-none;
            }

            .carousel-control-next,
            .carousel-control-prev {
                @extend .d-none;
            }
        }

        .hero-dashboard {
            bottom: 0;
            position: absolute;
        }
    }
}

// log in
.content-root.login {
    .form-container {
        @extend .row;
        @extend .justify-content-center;

        #login-target {
            @extend .col-8;
            margin-top: 1em;

            .content-box {
                @extend .theme-blue;
            }

            .login-btn {
                padding: 0 5rem;
            }

            .bottom-section {
                text-align: center;
                padding: 0 0 1em 0;
            }
        }
    }
}

.login-container {
    @extend .mx-auto;
    @extend .col-12;
    @extend .col-md-10;
    @extend .col-lg-8;

    .login-submit-button {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
    }

    form+div {
        @extend .content-box;
        padding: 10px;
    }
}

.content-root.recover-password {
    .recover-password-submit {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-outline;
        @extend .mx-auto;
    }

    .continue-to-login-btn {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
        @extend .my-4;
    }
}

.reset-pw-submit {
    @extend .btn;
    @extend .theme-btn;
    @extend .theme-primary;
    @extend .my-4;
}

// Enter logged out containers
.content-root.home,
.content-root.enter {
    .enter-logged-out,
    .next-drawing-card {
        h3 {
            @extend %theme-font-alternate-bold;
            font-size: 1.5rem;
        }

        .learn-more {
            font-size: 0.9em;
            line-height: 1.2em;
            padding: 1em 2em 1em 2em;
        }

        .line-under {
            @extend .mx-auto;
            @extend .mb-3;

            font-size: 0.9em;
            line-height: 1.2em;
            max-width: 350px;

            &:after {
                @extend .mt-3;
                background-color: $red-shade;
                content: '';
                display: block;
                height: 3px;
                left: 58%;
                margin-left: -15%;
                position: absolute;
                width: 15%;
            }
        }
    }
}

// password
.password-show {
    svg {
        color: $lottery-blue;
    }
}

// register
.content-root.register {
    h3 {
        @extend %theme-font-alternate-bold;
        @extend .theme-text-default-dark;
        font-size: 1.2em;
    }

    .registration-progress-bar {
        @extend .my-3;
        background: $white;
        box-shadow: 0 3px 5px $grey2;

        &::before {
            border-color: $lottery-blue;
            width: 55%;
        }
    }

    .step {
        .circle {
            @extend .rc-all-full;
            border: 1px solid $lottery-blue;
            color: $black;
            background: $lottery-yellow;
            font-size: 1rem;
            padding-top: calc(35px - 1rem);
            font-weight: 500;
            position: relative;
            z-index: 2;
        }

        &.active .circle {
            background-color: $lottery-blue;
            color: $white;
        }
    }
}

.resend-confirm-email-btn {
    @extend .btn;
    @extend .theme-btn;
    @extend .theme-primary;
    @extend .btn-lg;
}

.cant-find-confirmation-contact-btn,
.edit-email-btn {
    @extend .btn;
    @extend .theme-btn;
    @extend .theme-outline;
    @extend .btn-lg;
}

// my-profile
.content-root.my-profile {
    .navbar {
        border-radius: 0;
    }

    .page-head-title {
        @extend .rc-bottom-md;
        font-size: 3rem;
        text-transform: uppercase;
        text-align: center;
        padding: 0.1em 0;
        color: $grey2;
        background-color: $white;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.35);
        margin-bottom: 0.4em;
        border: 1px solid #cecece;
    }

    .update-info-button,
    .update-information-button, // Added for sg-ui-component class
    .update-email-button,
    .update-password-button,
    .update-password-submit,
    .update-email-submit {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
        @extend .mb-3;
    }

    #update-date-of-birth small div {
        display: inline-block;
    }

    // Override sg-ui-component combinator class button styles
    .btn.theme-btn.theme-secondary {
        background: $lottery-blue;
        color: $white;

        &:active {
            background-color: $theme-primary-active;
        }

        &:hover {
            color: $black;
        }

        &:disabled {
            background-color: $lottery-blue;
            opacity: 0.5;
        }
    }

    .phone-number-input {
        @extend .form-control;
        @extend .theme-input;
        @extend .col-md-3;
        @extend .px-3;
        max-width: 40%;
    }

    // Optins
    // Optins
    #my-notifications {
        h2 {
            font-size: 1.125rem;
        }

        .save-optins-btn {
            @extend .btn;
            @extend .theme-btn;
            @extend .theme-primary;
        }

        .save-instructions {
            color: #808080;
            font-size: 1rem;

            &::before {
                background-color: $lottery-yellow;
                content: '';
                display: block;
                height: 3px;
                width: 15%;
                top: 5px;
                position: relative;
                margin: 1rem 0;
            }
        }
    }
}

.update-password-button {
    @extend .btn;
    @extend .theme-btn;
    @extend .theme-primary;
    @extend .mb-3;
}

// site_login
.loginbox {
    background: #fff;
    padding: 1em 0;
    margin-bottom: 1em;
}

.loginbox p {
    margin: 0;
}

// Post Claim

.content-root.post-claim {
    .error-tooltip {
        color: $lottery-blue;
    }

    .scan-tickets-btn {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
        @extend .my-2;
    }

    .rewards-area {
        @extend .p-3;
        @extend .m-4;
        background: white;

        .congratulation-message {
            color: $lottery-blue;
            font-size: 1.625rem;
        }

        .your-earned-message {
            font-size: 1.25rem;
        }

        .promo-image {
            border-radius: $border-radius-sm;
        }

        .link-back-btn {
            @extend .btn;
            @extend .theme-btn;
            @extend .theme-primary;
        }

        .claim-box {
            background-color: $white;
            border: 1px solid $grey1;
            border-radius: 1em;
            -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.07);
            box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.07);
            overflow: hidden;

            .promotion-name {
                font-size: 1.125rem;
            }

            .reward-amount {
                font-size: 1.3125rem;
                font-weight: 600;
                color: $lottery-blue;
            }

            .reward-currency {
                font-size: 1rem;
            }

            .sweeps-cta a {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

// Sweepstakes Details
.content-root.second-chance-promotion {
    .sweepstakes-image {
        @extend .my-3;
        padding-right: 0;
    }

    .lottery-promotion-description {
        @extend .my-3;
        background: $white;
        box-shadow: 0px 0px 12px #00000080;
    }

    .theme-table-group-title {
        @extend %theme-font-alternate;
        color: $black;
        font-size: 1.125rem;
        border-bottom: 4px solid $lottery-yellow;
    }
}

// My Activity
.content-root.my-activity {
    .coupon {
        margin: 0;
    }
}

.coupon-modal {
    .print-coupon-btn {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
        padding: 0.5rem 1rem;
    }

    .coupon-storage {
        text-align: center;
        color: $lottery-red;
    }
}

// Refer a friend
.content-root.refer-a-friend {
    .raf-input {
        color: $lottery-blue;
    }
}

// Allocatable Entries
.allocatable-entries-form {
    .content-box {
        border: 0;

        .content-box-head {
            margin: 0 auto;
            width: 90%;

            @include media-breakpoint-up(sm) {
                width: 100%;
            }
        }

        .content-box-body {
            border: 1px solid $grey1;
        }
    }

    .entries-to-allocate {
        font-size: 18pt;
        font-weight: 700;
    }

    .drawing-group {
        border: 1px solid $grey2;
        border-radius: $border-radius-sm;

        .number-entries-field {
            max-width: 150px;

            input {
                font-weight: 600;
                text-align: center;
            }

            .increment {
                border-radius: 0 $border-radius-sm $border-radius-sm 0;
                font-weight: 700;
            }

            .decrement {
                border-radius: $border-radius-sm 0 0 $border-radius-sm;
                font-weight: 700;
            }

            .numbers-entry-label {
                color: $grey2;
            }
        }

        .prize-details {
            .card {
                border: none;

                .card-header {
                    background: $white;
                    border: none;
                    padding: 0;
                }

                .card-body {
                    padding: 1rem 0;
                }
            }
        }
    }

    .submit-allocatable {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-secondary;
        width: 90%;
        max-width: 300px;
        margin: 0 auto;
        text-transform: uppercase;
        z-index: 1;
    }

    .entries-submitted-success {
        border: 1px solid $theme-success;
        border-radius: 15px;
        padding: 0.625em;
        text-align: center;
        color: $theme-success;
        font-weight: bold;
    }
}

.prize-popover {
    h3 {
        color: $lottery-blue;
        font-size: 1.4rem;
    }
}

// Galaxy Fold Fix - for very small screens.
@media (max-width: 280px) {
    .galaxy-fold-open-your-device {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 2147483647;
    }

    .galaxy-fold-open-your-device::after {
        content: 'Please unfold your phone to access our site';
        display: block;
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.login-template {
    .login-template-card {
        max-width: 800px;
        margin: auto;
    }

    .login-template-header {
        color: $white;
        font-size: 19px;
    }

    .login-template-body {
        .players-club-note {
            max-width: 400px;
            margin: auto;
            font-weight: normal;
        }
    }

    .login-template-footer {
        color: $white;
        font-size: 18px;
    }

    .login-template-button {
        background-image: linear-gradient(160deg, #73c167, #30842a);
        color: $white;
        text-transform: uppercase;
        width: 250px;
        font-size: 17px;
        border-radius: 0;
        border: 0;

        &:hover,
        &:active {
            background: #37862a !important;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .login-template-form {
        width: 90%;
        margin: auto;

        .form-group {
            text-align: left;
        }

        .forgot-password {
            display: block;
            font-style: italic;
            margin: 10px 0;
            color: $theme-default;
            text-align: left;
            text-decoration: underline;
        }
    }
}

.content-root.forgot-password {
    .resend-email-btn {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-outline;
        @extend .btn-block;
    }

    .forgot-password-submit-btn {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
        @extend .btn-block;
    }
}

.forgot-password-container {
    .resend-email-button {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
    }
}

.enter-tickets-only-scan {
    h4 {
        font-size: 1rem;
    }

    h5 {
        font-size: 1.1rem;
    }

    p {
        font-size: 0.9rem;
    }

    .theme-btn {
        font-size: 1rem;
        @media (min-width: 400px) {
            width: 300px;
        }
    }
}

.system-is-offline {
    color: $lottery-red;
    font-weight: bold;
    padding: 10px 0;
}

.next-drawing-card {
    text-align: center;
    color: $theme-default;
    font-size: 0.9em;

    h3 {
        color: $theme-default;
        font-size: 1.5rem;
    }

    .more-chances {
        font-size: 0.9em;
        padding-top: 15px;
        border-top: 0px;
        width: 100%;
        position: relative;

        &:before {
            @extend .mt-3;
            background-color: $red-shade;
            content: '';
            display: block;
            height: 3px;
            left: 58%;
            margin-left: -15%;
            position: absolute;
            width: 15%;
            top: 5px;
            margin-top: 0 !important;
        }

        .theme-btn {
            font-size: 1rem;
            @media (min-width: 400px) {
                width: 300px;
            }
        }
    }

    .theme-btn.btn-block {
        background-color: #969696;
        color: #fff;
    }
}

.theme-hr {
    width: 70px;
    border-top: 3px solid rgba(0, 0, 0, 0.5);
}

.post-claim {
    .congratulation-message {
        @include media-breakpoint-down(md) {
            font-size: 1.3rem;
        }
    }
}

.reveal-game-modal {
    .modal-header {
        @extend .text-uppercase;
        color: $white;
        font-size: 1.35rem;
    }

    .modal-footer {
        .footer-description {
            color: $mint;
            @extend .text-uppercase;
            @extend .text-center;
            font-weight: 600;
        }

        .skip-button {
            @extend .text-uppercase;
            @extend .mx-auto;
            @extend .my-2;
        }
    }
}

// Surveys
.survey-container {
    .content-box {
        @extend .mx-3;
    }

    .survey-submit {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-primary;
    }

    .form-group {
        & > label {
            font-weight: bold;
        }
    }
}

.home,
.enter {
    .scan-tickets-btn {
        @extend .btn;
        @extend .theme-btn;
        @extend .theme-secondary;
        @extend .my-2;
    }

    .entry-box {
        @extend .mb-3;

        .enter-logged-out {
            h3 {
                color: $theme-default;
                font-size: 1.5rem;
            }
        }

        .ticket-entry-blurb {
            @extend .p-3;
            background: $white;
            box-shadow: 0px 0px 10px #000000a2;
            min-height: 285px;

            h3 {
                color: $lottery-blue;
            }
        }

        .enter-tickets-area {
            min-height: 285px;

            .content-box-body {
                padding: 0;
            }

            .nav-tabs .nav-link {
                background-color: #eee;
                text-align: center;
                width: 50%;

                &.active {
                    background-color: #fff;
                }
            }

            p {
                margin: 0;
            }

            .tab-content {
                padding: 1rem;

                label {
                    display: block;
                    margin-bottom: 0;
                    width: 100%;
                }

                .view-history-link {
                    font-size: 0.9em;
                }

                .ticket-number-inputs:last-of-type span {
                    display: none;
                }
            }

            .ticket-tooltip {
                font-size: 1.2rem;
                margin-left: 5px;
                color: $lottery-blue;
            }

            .ticket-number-inputs:last-of-type {
                .dash {
                    display: none;
                }
            }
        }
    }
}

.drawings-and-winners {
    .winners-link {
        text-transform: uppercase;
    }
}

.winners-modal.modal-dialog {
    max-width: 90vw;

    .modal-content {
        border: none;
        border-radius: 0;

        .mobile-winner-chunk {
            margin-bottom: 1em;
        }

        .modal-header {
            border-radius: 0;
            border: none;

            .modal-close-element {
                margin-left: auto;
                cursor: pointer;
            }
        }
    }

    .sweeps-table {
        caption {
            font-size: 2rem;
            color: #000;
            caption-side: top;
            text-align: left;
            line-height: 1.5em;
        }
    }
}

.points-for-drawings-form-container {
    @extend .p-3;
    background: $white;
    box-shadow: 0px 0px 12px #00000080;
}
.pfd-list {
    .pfd-card {
        border-radius: 10px;
        background: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        font-family: Arial, sans-serif;
        max-width: 300px;
        margin: auto;
        margin-bottom: 20px;
    }

    .card-image {
        position: relative;
    }

    .card-img-top {
        width: 100%;
        display: block;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

     .pfd-date-icon {
        margin-right: 5px; /* Add space between the icon and text */
    }
    .bottom-image-date {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $theme-blue;
        color: $white;
    }

    .bottom-image-date div {
        margin-right: 5px;
    }

    .pfd-point-icon {
        margin-right: 5px;
    }


    .card-body {
        margin-top: 5px;
        padding: 15px;
    }

    .card-points,
    .card-entries {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;
        font-weight: bold;
    }

    .card-title {
        font-size: 1.2em;
        font-weight: bold;
        padding: 5px 0;
        margin-top: 5px;
        text-align: start;
    }

    .card-description {
        padding: 10px 0;
        font-weight: bold;
        color: #333;
    }

    .card-action {
        text-align: center;
        padding: 10px 0;
    }

    .more-link {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin-bottom: 20px;

        a {
            background: $theme-blue;
            color: white;
            padding: 10px 20px;
            border-radius: 20px;
            text-decoration: none;
            text-align: center;
            min-width: 15rem;
        }
    }

    .fa {
        margin-right: 5px;
    }
}

.pfd-more-link {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin-top: 26px;
    margin-bottom: 20px;

    a {
        color: white;
        background: $theme-blue;
        padding: 5px 20px;
        border-radius: 20px;
        text-decoration: none;
        text-align: center;
        width: 20rem;

        @media (max-width: 767px) {
            max-width: 90%;
            padding: 5px 10px;
        }
    }
}


// loading indicator
.loading-indicator {
    .loading-indicator-spinner {
        display: inline-block;
        width: 3.125rem;
        height: 3.125rem;
        border: 3px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top-color: $theme-primary;;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
        margin: 1.875rem;
    }

    @keyframes spin {
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    .loading-indicator-fail-message {
        @extend .text-center;
        animation: reveal-fail-message 10s linear;

        p {
            color: #333;
            font-size: .875rem;
            line-height: normal;
            padding: 0 .3125rem;
        }

        @keyframes reveal-fail-message {
            0% {
                display: none;
            }
            99% {
                display: none;
            }
            100% {
                display: initial;
            }
        }

        @-webkit-keyframes reveal-fail-message {
            0% {
                display: none;
            }
            99% {
                display: none;
            }
            100% {
                display: initial;
            }
        }
    }
}

.ul-center-bullets {
    list-style-position: inside;
    padding-left: 0;
}

.action-notification {
    .modal-content {
        background: none;
        border: none;
        border-radius: none;
    }

    .action-notification-image {
        @extend .text-center;
        @extend .my-4;

        img {
            @extend .img-fluid;
        }
    }

    .action-notification-description {
        @extend .text-center;
        @extend .my-4;
        @extend .mx-2;
    }

    .action-notification-buttons {
        @extend .text-center;
        @extend .my-2;

        a {
            text-decoration: none;
        }

        small a {
            text-decoration: underline;
        }
    }

    .action-notification-buttons-cta {
        @extend .btn;
        @extend .btn-primary;
        @extend .mx-2;
    }

    .action-notification-alert {
        @extend .alert;
        @extend .alert-info;
        @extend .text-center;
        display: block !important;
    }

    .action-notification-alert-list {
        @extend .ul-center-bullets;
        @extend .mb-0;
    }
}

.update-password-button {
    @extend .btn;
    @extend .btn-secondary;
    @extend .mx-2;
}

.delete-account {
    .checkbox-section {
        @extend .m-4;

        label {
            font-weight: 500 !important;
        }
    }

    .submit-section {
        @extend .text-center;

        .delete-account-button {
            @extend .btn;
            @extend .btn-danger;
            @extend .mx-2;
        }
    }
}

.terms {
    #terms-of-use-content {
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-x: hidden;
        height: 350px;
        padding: 20px;
        margin: 0;
    }

    .accept-terms-of-use-section{
        @extend .text-center;
        @extend .mx-auto;
        @extend .mt-3;
    }
}

.decline-terms-of-use-modal {
    .modal-content {
        background: none;
        border: none;
        border-radius: none;
    }

    .modal-content-body {
        @extend .text-center;
    }
}

.accept-tos-btn {
    @extend .btn;
    @extend .theme-btn;
    @extend .theme-secondary;
    @extend .btn-lg;
    @extend .m-2;
}

.decline-tos-btn {
    @extend .btn;
    @extend .theme-btn;
    @extend .theme-outline;
    @extend .btn-lg;
    @extend .m-2;
}
